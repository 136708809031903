import React, { createRef } from "react"
import Bg from "../../images/bg.png"
import { BackBtn } from "../../components/icons"
import PropertyCard from "../../components/propertyCard"
import clarenVilla from "../../assets/geradines/clarenVilla/gallery/Claren AP1.jpg"
import skyVilla from "../../assets/geradines/skyVilla/gallery/Skyvilla.png"
import paramount from "../../assets/geradines/paramount/gallery/paramount.png"
import Grenadines from '../../assets/geradines/geradineResort/gallery/18.jpg'
import shonnyParkImg from "../../assets/geradines/shonnyPark/gallery/Shonnypark place.jpg"
import l5BananaImg from "../../assets/geradines/l5/gallery/image 4.jpg"
import clarenceGateImg from "../../assets/geradines/calerngate/gallery/Clarence 2.jpg"
import Slider from "react-slick"
import GrenadinesLogo from "../../assets/geradines/GrenadinesLogo.png"
import { SampleNextArrow, SamplePrevArrow } from "../../components/arrowButtons"

const Property = () => {
  const customeSlider = createRef();
  const gotoNext = () => {
    customeSlider.current.slickNext()
  }

  const gotoPrev = () => {
    customeSlider.current.slickPrev()
  }
  const data = [
    {
      id: 1,
      title: "CLAREN VILLA",
      imgUrl: clarenVilla,
      link: "grenadines/claren-villas",
      text:
        "Claren Villas is designed for the upper class looking for out-of-the-ordinary homes that beat the conventional...",
    },
    {
      id: 2,
      title: "SKYVILLA",
      imgUrl: skyVilla,
      link: "grenadines/skyvilla",
      text:
        "SKYVILLA offers a private enclave of luxury contemporary residences that defines a style of living, elegance and beauty...",
    },
    {
      id: 3,
      title: "PARAMOUNT TWIN TOWERS",
      imgUrl: paramount,
      link: "grenadines/paramount",
      text:
        "Paramount Twin Towers is an unprecedented residential masterpiece just steps away from the Atlantic...",
    },
    {
      id: 4,
      title: "Shonny Park Place",
      imgUrl: shonnyParkImg,
      link: "grenadines/shonny-park-place",
      text:
        "Shonny Park Place is a development infused with the legacy and reliability of the Shonibare Family and the innovativeness of Grenadines Homes.",
    },
    {
      id: 5,
      title: "Grenadines Resort",
      imgUrl: Grenadines,
      link: "grenadines/grenadines-resort",
      text:
        "Grenadines Resort, Katampe gives you entry into an exclusive gated community of individuals who appreciate and desire quality, exclusivity and serenity...",
    },
    {
      id: 6,
      title: "L5 Banana",
      imgUrl: l5BananaImg,
      link: "grenadines/l5-banana",
      text:
        "L5 Banana by Grenadines Homes is a signature address within the prestigious Banana Island neighbourhood, designed for the upper class looking for out-of -the-ordinary homes that beats the conventional and an opportunity to reward themselves...",
    },
    {
      id: 6,
      title: "Clarence Gate",
      imgUrl: clarenceGateImg,
      link: "grenadines/clarence-gate",
      text:
        "Clarence Gate, Banana Island is an extraordinary home with everything about it graded five-star, from design concept to exquisite ­nishing.",
    },
  ]

  const settings = {
    dots: true,
    dotsClass: "line-dots property",
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <>
          <div data-dots={i + 1} className="dots">
            &nbsp;
          </div>
        </>
      )
    },
  }



  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col w-full">
        <div className="relative md:flex md:justify-center md:items-stretch md:w-full">

          <div
            className="relative min-h-full md:min-h-screen py-12 px-6 md:p-12 md:w-full md:flex md:items-center md:justify-center"
            style={{
              backgroundImage: `url(${Bg} )`,
            }}
          >
            <div className="relative md:absolute top-0 left-0 right-0 md:pr-6 md:pl-6 md:mr-16 md:ml-16 md:mt-12 flex-col md:flex-row flex justify-between md:items-center">
              <div className="w-1/3">
                <BackBtn
                  color={"#982A7D"}
                  height={"20px"}
                  onClick={() => window.history.back()}
                />
              </div>
              <div className="md:w-1/3 my-4 md:my-0 mx-auto">
                <img src={GrenadinesLogo} className="h-16 mx-auto m-0" />
              </div>
              <div className="md:w-1/3 text-right mx-auto">
              </div>
            </div>
            
            <SamplePrevArrow onClick={gotoPrev} />
            <Slider
              {...settings}
              ref={customeSlider}
              className="overflow-hidden py-12 mt-24 property-slide"
            >
              {data.map((el, key) => (
                <PropertyCard key={key} {...el} />
              ))}
            </Slider>
            <SampleNextArrow onClick={gotoNext} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Property
